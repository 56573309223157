import type { NextPageContext } from 'next';
import NextError from 'next/error';
import * as Sentry from '@sentry/nextjs';

type MyErrorProps = {
  statusCode: number;
  hasGetInitialPropsRun: boolean;
  err?: Error;
};

type CustomerAPIError = {
  response: {
    status: number;
    data: {
      error?: string;
      message?: string;
    };
  };
};

interface Context extends Omit<NextPageContext, 'err'> {
  err: NextPageContext['err'] | CustomerAPIError;
}

const MyError = ({
  hasGetInitialPropsRun,
  statusCode,
  err,
}: MyErrorProps): JSX.Element => {
  if (!hasGetInitialPropsRun && err)
    Sentry.captureException(err, {
      tags: {
        description: `_error > JSX `,
      },
    });
  return <NextError statusCode={statusCode} />;
};

MyError.getInitialProps = async (context: NextPageContext) => {
  const errorInitialProps = await NextError.getInitialProps(context);

  const { res, err, asPath } = context as Context;

  if (res?.statusCode === 404) {
    return errorInitialProps;
  }

  if (res?.statusCode === 500 && asPath === '/auth') {
    const error = err as CustomerAPIError;
    const dontSendError =
      error.response.data?.message === 'Customer not found' ||
      error.response.data?.error === 'INVALID_QUERY_FILTER_OPERATOR';

    if (!dontSendError) {
      const scope = new Sentry.Scope();
      scope.setContext('Customer API Error Data', {
        status: (err as CustomerAPIError).response.status,
        message:
          (err as CustomerAPIError).response.data.message || 'Message not set',
        error: (err as CustomerAPIError).response.data.error || 'Error not set',
      });
      scope.setTag('isNextAPIError', true);
      Sentry.captureException(err, scope);
    }
    return errorInitialProps;
  }

  if (err) {
    Sentry.captureException(err, {
      tags: {
        description: `unknown error`,
      },
    });
    await Sentry.flush(2000);

    return errorInitialProps;
  }
  Sentry.captureException(new Error(`Erro no path ${asPath}`), {
    tags: {
      description: 'Generated without error',
    },
  });
  await Sentry.flush(2000);

  return errorInitialProps;
};

export default MyError;
